

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-table {
  .el-table__fixed-right {
    height: auto !important; // 此处的important表示优先于element.style
    bottom: 12px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变
  }
}
.TrainingRecordsSee /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  min-width: 64px;
  height: 82px;
  display: flex;
}

.TrainingRecordsSee /deep/.el-upload-list.el-upload-list--picture-card {
  display: flex;
}
.hide /deep/.el-upload--picture-card {
  display: none !important;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.certifiedPhoto {
  height: 350px;
  position: relative;
  .zpShell {
    display: flex;
    margin: 20px 0;
    .zpList {
      width: 110px;
      margin: 0 5px;
      cursor: pointer;
      position: relative;
      img {
        width: 110px;
        height: 130px;
      }
      .p1 {
        // overflow: hidden; //超出的文本隐藏
        // display: -webkit-box;
        // -webkit-line-clamp: 2; // 超出多少行
        // -webkit-box-orient: vertical;
      }
      .p2 {
        font-size: 12px;
        margin-top: 10px;
      }
      i {
        color: #409EFF;
        font-size: 28px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .noData {
      width: 100%;
      img {
        width: 230px;display: block; margin: auto;
      }
      p {
        color: #a3a3a3;
        text-align: center;
      }
    }
  }
  .pageNum {
    clear: both;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
