.el-table .el-table__fixed-right {
  height: auto !important;
  bottom: 12px;
}
.TrainingRecordsSee /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  min-width: 64px;
  height: 82px;
  display: flex;
}
.TrainingRecordsSee /deep/ .el-upload-list.el-upload-list--picture-card {
  display: flex;
}
.hide /deep/ .el-upload--picture-card {
  display: none !important;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog .el-form-item {
  width: 44%;
  float: left;
  margin-bottom: 0.5rem;
  margin: 10px 3%;
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.certifiedPhoto {
  height: 350px;
  position: relative;
}
.certifiedPhoto .zpShell {
  display: flex;
  margin: 20px 0;
}
.certifiedPhoto .zpShell .zpList {
  width: 110px;
  margin: 0 5px;
  cursor: pointer;
  position: relative;
}
.certifiedPhoto .zpShell .zpList img {
  width: 110px;
  height: 130px;
}
.certifiedPhoto .zpShell .zpList .p2 {
  font-size: 12px;
  margin-top: 10px;
}
.certifiedPhoto .zpShell .zpList i {
  color: #409EFF;
  font-size: 28px;
  position: absolute;
  top: 0;
  right: 0;
}
.certifiedPhoto .zpShell .noData {
  width: 100%;
}
.certifiedPhoto .zpShell .noData img {
  width: 230px;
  display: block;
  margin: auto;
}
.certifiedPhoto .zpShell .noData p {
  color: #a3a3a3;
  text-align: center;
}
.certifiedPhoto .pageNum {
  clear: both;
  position: absolute;
  bottom: 0;
  right: 0;
}
